.viewer-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    border: 1px solid black;
    top: 0px;
    background-color: #f0f0f0;
}

.viewer-container {
    position: absolute;
    width: 100%;
    height: 100%;
}